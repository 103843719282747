import React from "react";

function Features() {
  const features = [
    {
      icon: "images/feature_1.svg",
      title: "Usage-Logs Policy",
      desc: `We don't monitor, store or keep track of your online activities.
      User privacy is our main focus`,
    },
    {
      icon: "images/feature_2.svg",
      title: "World-Class Protocols",
      desc: `Private Internet the leading create the security providing safety`,
    },
    {
      icon: "images/feature_4.svg",
      title: "Anonymous Payments",
      desc: `With Private VPN you can pay without revealing your identity using
      any anonymous online payment method`,
    },
  ]
  return (
    <section
      id="features"
      className="bg-gradient-to-b from-[#F1F5F9] to-[#FFFFFF00] py-[120px]"
      data-aos="fade-down"
      data-aos-duration="500"
      data-aos-once="true"
    >
      <div className="container px-[24px] mx-auto h-fit">
        <div className="flex items-center justify-center">
          <h1 className="font-bold text-[40px] lg:text-[56px] text-center text-[#3C3C3C] relative inline-block">
            Features<span className="hidden sm:block heading-ring"></span>
          </h1>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-[58px] mt-[120px]">

          {features.map((feature, idx)=>(
            <div key={idx} className="mb-[20px] mx-auto flex flex-col items-center justify-center w-[300px] sm:w-auto xl:w-[350px]">
            <img className="mb-[16px]" src={feature.icon} alt="" />
            <h4 className="text-[24px] font-medium leading-[34px] text-[#0F172A] mb-[16px]">
              {feature.title}
            </h4>
            <p className="text-[#3C3C3C] leading-[26px] text-center">
              {feature.desc}
            </p>
          </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Features;
