import React, { useEffect } from "react";
import Aos from "aos";
function TermsConditions() {

  useEffect(() => {
    window.scrollTo(0, 0);
    Aos.init({
      duration: 1000,
      easing: "ease-in",
      once: true,
    });
  }, []);
  return (
    <div className="container mx-auto py-[70px] text-justify ">
      <h1 className="text-[40px] text-center mb-[20px]">
        Terms and Conditions
      </h1>

      <div className="container px-[40px]">
        <p className="my-[10px]">
          Please carefully read these Terms and Conditions ("Terms") before
          downloading or using the 9D VPN app ("the app"). By downloading or
          using the app, you automatically agree to be bound by these Terms. If
          you do not agree with any part of these Terms, you are not allowed to
          download or use the app.
        </p>

        <ul className="list-disc">
          <li className="my-[10px]">
            <span className="font-bold">Ownership and Restrictions:</span> You
            are not permitted to copy, modify, or reproduce the app or any part
            of it, including our trademarks, in any manner. Attempting to
            extract the app's source code, translating it into other languages,
            or creating derivative versions is strictly prohibited. All
            intellectual property rights, including trademarks, copyright, and
            database rights, related to the app, remain the property of 9D
            TECHNOLOGIES (SMC-PRIVATE) LIMITED.
          </li>

          <li className="my-[10px]">
            <span className="font-bold">App Changes and Charges:</span>9D
            TECHNOLOGIES (SMC-PRIVATE) LIMITED reserves the right to make
            changes to the app or introduce charges for its services at any time
            and for any reason. Any charges will be transparently communicated
            to you, and you will have the option to accept or decline.
          </li>

          <li className="my-[10px]">
            <span className="font-bold">Personal Data:</span> The 9D VPN app
            processes personal data provided by you to deliver our services. It
            is your responsibility to maintain the security of your device and
            app access. We advise against jailbreaking or rooting your phone, as
            it may compromise the app's functionality and security.
          </li>

          <li className="my-[10px]">
            <span className="font-bold">Third-Party Services:</span> The app
            utilizes third-party services that have their own Terms and
            Conditions. You can find links to these third-party Terms and
            Conditions below:
          </li>
          <li className="my-[10px] ml-[20px]">
            <a
              href="https://firebase.google.com/terms/analytics"
              target="_blank"
              className="text-[#3E5FFF]"
            >
              Google Analytics for Firebase
            </a>
          </li>
          <li className="my-[10px] ml-[20px]">
            <a
              href="https://firebase.google.com/terms/crashlytics"
              target="_blank"
              className="text-[#3E5FFF]"
            >
              Firebase Crashlytics
            </a>
          </li>

          <li className="my-[10px]">
            <span className="font-bold">Limitations of Responsibility: </span>{" "}
            9D TECHNOLOGIES (SMC-PRIVATE) LIMITED shall not be held responsible
            for certain aspects, including but not limited to:
          </li>
          <li className="my-[10px]">
            <span className="font-bold">Third-Party Services:</span> The app
            utilizes third-party services that have their own Terms and
            Conditions. You can find links to these third-party Terms and
            Conditions below:
          </li>
          <li className="my-[10px]  ml-[20px]">
            App functionality when no active internet connection is available.
          </li>
          <li className="my-[10px]  ml-[20px]">
            Data charges incurred while using the app, according to your mobile
            network provider's terms.
          </li>
          <li className="my-[10px]  ml-[20px]">
            The way you use the app and its impact on your device's battery
            life.
          </li>
          <li className="my-[10px]  ml-[20px]">
            Reliance on Third-Party Information:
          </li>
          <li className="my-[10px]  ml-[20px]">
            While we strive to keep the app updated and accurate, we rely on
            third-party information providers. Therefore, 9D TECHNOLOGIES
            (SMC-PRIVATE) LIMITED assumes no liability for any direct or
            indirect loss resulting from your complete reliance on the app's
            functionality.
          </li>
        </ul>
        <p className="font-bold text-[18px]">App Updates and Termination:</p>
        <ul className="list-disc">
          <li className="my-[10px]">
            We may release updates for the app. It is your responsibility to
            download and install these updates to continue using the app. We
            reserve the right to terminate the app's availability at any time
            without prior notice. Upon termination, your rights and licenses
            granted by these Terms will cease, and you must cease using and, if
            necessary, delete the app from your device.
          </li>
        </ul>
        <p className="font-bold text-[18px]">
          Changes to Terms and Conditions:
        </p>
        <ul className="list-disc">
          <li className="my-[10px]">
            These Terms and Conditions may be updated periodically. We will post
            the revised Terms on this page and notify you of any changes.
          </li>
        </ul>
        <p>Effective Date: 2023-07-27</p>
        <p className="font-bold text-[18px]">Contact Us:</p>
        <p>
          If you have any questions or suggestions regarding these Terms and
          Conditions, feel free to contact us at
          <a href="mailto:info@9dtechnologies.com" className="text-[#3E5FFF]">
            info@9dtechnologies.com.
          </a>
        </p>
      </div>
    </div>
  );
}

export default TermsConditions;
