import { useEffect } from "react";
import { decodeToken } from "react-jwt";
import { useAuth } from "../context/AuthContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import newRequest from "../utils/newRequest";

function PaymentSuccess() {
  const { setUser, user } = useAuth();
  const [searchParams] = useSearchParams();
  const platform = searchParams.get("platform");
  const navigate = useNavigate();

  useEffect(() => {
    const user = decodeToken(localStorage.getItem("token"));
    if (!user || searchParams.size === 0) {
      navigate("/");
      return;
    }
    let email = user?.email;

    const headers = {
      platform,
    };
    const getToken = async () => {
      try {
        const response = await newRequest.post(
          "/update-session",
          {
            email,
          },
          { headers }
        );
        setUser(decodeToken(response.data.token));
        localStorage.setItem("token", response.data.token);
      } catch (err) {
        navigate(`/payment/cancel?platform=${platform}`);
      }
    };
    getToken();
  }, []);

  return (
    <>
      <div className="bg-gradient-to-r from-[#F1F5F9] to-[#FFFFFF] w-full py-16 h-[calc(100vh-281px)]">
        <div className="flex flex-col items-center justify-center w-full h-full">
          <div className=" w-full md:w-[660px]  rounded-[12px] border-[1px] border-[#ECECEC] px-5 py-[50px] sm:px-[50px] bg-white shadow ">
            <div className="flex flex-col gap-10">
              <h1 className="text-[40px] font-bold">Congratulations!</h1>
              <p className="leading-8">
                We've sent all the details to {user?.email}. For confirmation
                check your Email or see subscriptions details.
              </p>
              <button className="w-[489px] bg-[#3E5FFF] text-[#ffffff] text-[24px] font-bold rounded-[300px] px-[20px] py-[18px]">
                Go to Dashboard
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentSuccess;
