import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

function Downloads() {
  const [currPlatform, setCurrPlatform] = useState(null);
  const platformsInfo = {
    windows: {
      name: "Windows",
      heroImg: "/images/platforms/windows-img.svg",
      icon: "/images/platforms/windows.svg",
      heading: "Get the Most Secure Free VPN for Your Windows Computer",
      para: "Install 9D VPN on your Windows system for secure, worry-free internet browsing, enhancing both your privacy and productivity.",
      link: "",
    },
    mac: {
      name: "Mac",
      heroImg: "/images/platforms/mac-img.svg",
      icon: "/images/platforms/mac.svg",
      heading: "Get the Most Secure Free VPN for Your Mac",
      para: "Install 9D VPN on your Mac system for secure, worry-free internet browsing, enhancing both your privacy and productivity.",
      link: "",
    },
    linux: {
      name: "Linux",
      heroImg: "/images/platforms/windows-img.svg",
      icon: "/images/platforms/linux.svg",
      heading: "Get the Most Secure Free VPN for Your Windows Computer",
      para: "Install 9D VPN on your Windows system for secure, worry-free internet browsing, enhancing both your privacy and productivity.",
      link: "",
    },
    android: {
      name: "Android",
      heroImg: "/images/platforms/android-img.svg",
      icon: "/images/platforms/android.svg",
      heading: "Download the Secure Free VPN for Android",
      para: "9D VPN for Android devices ensures secure browsing and encrypts sensitive data like online banking, emails, and other private activities to prevent leaks.",
      link: "",
    },

    ios: {
      name: "iOS",
      heroImg: "/images/platforms/ios-img.svg",
      icon: "/images/platforms/ios.svg",
      heading: "Download the Secure Free VPN for iOS",
      para: "9D VPN for iOS ensures secure browsing and encrypts sensitive data like online banking, emails, and other private activities to prevent leaks.",
      link: "",
    },
  };

  const { platform } = useParams();

  useEffect(() => {
    if (platformsInfo[platform]) {
      window.scrollTo(0, 0);
      setCurrPlatform(platformsInfo[platform]);
    } else {
      console.log("Page 404");
    }
  }, [platform]);

  return (
    <>
      {currPlatform && (
        <section>
          <div className="container mx-auto h-fit py-[80px] ">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-[24px]">
              <div className="flex flex-col item-start justify-center">
                <div className="flex items-center">
                  <h1 className="text-[45px] sm:text-[60px] text-center lg:text-left font-bold leading-[66px] relative inline-block 2xl:w-[80%]">
                    {currPlatform.heading}{" "}
                    <span className="hidden sm:block heading-ring downloads-hero-ring z-[-10]"></span>
                  </h1>
                </div>
                <p className="text-[18px] font-normal leading-[28px] mt-[24px] 2xl:w-[80%] text-center lg:text-left">
                  {currPlatform.para}
                </p>
                <div className="flex items-center flex-col md:flex-row gap-4 mt-12">
                  <a className="py-[22px] w-[335px] bg-[#2563EB] rounded-[300px] text-[24px] text-[#FFFFFF] text-center font-bold  mx-auto lg:mx-0">
                    Download for {currPlatform.name}
                  </a>
                </div>
              </div>
              <div className="h-[627px]">
                <div className="flex justify-center items-center h-full relative">
                  <img src={currPlatform.heroImg} alt="" className="" />
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      <section className="bg-gradient-to-b from-[#F1F5F9] to-[#FFFFFF00] py-[120px]">
        <div className="container px-[24px] mx-auto h-fit">
          <div className="flex items-center justify-center">
            <h1 className="font-bold text-[40px] lg:text-[56px] text-center text-[#3C3C3C] relative inline-block">
              Download For All Your Devices
              <span className="hidden sm:block heading-ring"></span>
            </h1>
          </div>

          <div className="md:w-[620px] lg:w-[950px] mx-auto flex justify-center md:justify-start gap-12 mt-[72px] mb-[120px] flex-wrap">
            {/* <div className="w-[284px] h-[284px] rounded-[20px] bg-white"></div> */}
            {Object.keys(platformsInfo).map((platformKey) => (
              <Link key={platformKey} to={`/downloads/${platformKey}`}>
              <div className="w-[284px] h-[284px] rounded-[20px] bg-white flex flex-col items-center justify-center gap-3">
                <img src={platformsInfo[platformKey].icon} alt="" />
                <p className="text-[18px]">{platformsInfo[platformKey].name}</p>
              </div>
              </Link>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default Downloads;
