import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import ForgetPass from "./pages/ForgetPass";
import ResetPass from "./pages/ResetPass";
import Profile from "./pages/Profile";
import DesktopPricing from "./pages/DesktopPricing";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsConditions from "./pages/TermsConditions";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Downloads from "./pages/Downloads";
import PaymentSuccess from "./pages/PaymentSuccess";
import VerifyOTP from "./pages/VerifyOTP";
import { AuthProvider } from "./context/AuthContext";
import PaymentCancel from "./pages/PaymentCancel";

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
          <Routes>
            <Route
              element={
                <>
                  <Navbar />
                  <Outlet />
                  <Footer />
                </>
              }
            >
              <Route path="/" element={<Home />} />
              <Route path="/desktop-plans" element={<DesktopPricing />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route
                path="/terms-and-conditions"
                element={<TermsConditions />}
              />
              <Route path="/profile" element={<Profile />} />

              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Signup />} />
              <Route path="/verify" element={<VerifyOTP />} />
              <Route path="/forget-password" element={<ForgetPass />} />
              <Route path="/reset-password" element={<ResetPass />} />
              <Route path="/payment/success" element={<PaymentSuccess />} />
              <Route path="/payment/cancel" element={<PaymentCancel />} />
              <Route path="/downloads/:platform" element={<Downloads />} />
            </Route>
          </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
