import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { decodeToken, isExpired } from "react-jwt";

function Navbar() {
  const { user, setUser } = useAuth();
  const [showProfileCard, setShowProfileCard] = useState(false);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const dropdownRef = useRef(null);
  const path = useLocation();

  const navigate = useNavigate();

  const toggleMenuMob = () => {
    setIsOpenMenu(!isOpenMenu);
  };

  const closeMenu = () => {
    setIsOpenMenu(false);
  };

  const handleLinkClick = () => {
    closeMenu();
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeMenu();
      }
    };

    window.addEventListener("click", handleOutsideClick);

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const location = useLocation();
  useEffect(() => {
    // Hide profile card on route change
    setShowProfileCard(false);
    // toggleMenuMob();
    setIsOpenMenu(false);
  }, [location]);

  
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      setUser(null);
      return;
    }
    const expired = isExpired(token);
    if (expired) {
      alert("login again session expired");
      setUser(null);
      localStorage.removeItem("token");
      navigate("/");
      return;
    }
    const loggedUser = decodeToken(token);
    setUser(loggedUser);
  }, []);

  return (
    <nav
      id="navbar"
      className="border-b-[1px] border-[#E2E8F0] sticky top-0 bg-[#FFFFFF] z-10"
    >
      <div className="container mx-auto px-[24px] py-[16px] h-[96px] relative">
        {/* profile dropdown card  */}
        {showProfileCard && user && (
          <div
            className={`hidden lg:block w-[563px] h-[379px] px-[50px] rounded-[15px] bg-white profile-shadow absolute right-6 top-[80px] z-100`}
          >
            <div>
              <div className="flex items-center gap-8 py-[47px] border-b-[1px] border-[#D9D9D9] ">
                <div className="w-[116px] h-[116px] rounded-[50%] p-6 bg-[#e2e7ff]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    className="w-full h-full "
                  >
                    <path
                      fill="#3e5fff"
                      d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM49.3 464H398.7c-8.9-63.3-63.3-112-129-112H178.3c-65.7 0-120.1 48.7-129 112zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3z"
                    />
                  </svg>
                </div>

                <div>
                  <p className="text-[24px] font-bold text-[#222B42] capitalize">
                    {user?.name}
                  </p>
                  <p className="text-[#5C6977] tracking-[1.6px] ">
                    {user?.email}
                  </p>
                </div>
              </div>

              <div className="flex flex-col gap-10 py-9">
                <div className="flex gap-5">
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1744_1579)">
                      <path
                        d="M12.4453 20.8827H9.6489L10.708 19.8218C10.9823 19.547 10.9819 19.1018 10.7071 18.8274C10.4328 18.5536 9.98747 18.5531 9.71281 18.8283L7.45731 21.0876C7.17918 21.3661 7.18284 21.8237 7.47343 22.0973L9.71281 24.3405C9.9871 24.6153 10.4322 24.6157 10.7071 24.3414C10.9819 24.0669 10.9823 23.6218 10.708 23.347L9.65183 22.289C12.8381 22.289 12.4407 22.2892 12.4755 22.2892C19.9087 22.2892 24.6269 14.1994 20.8996 7.69693C20.7066 7.3602 20.2773 7.24338 19.94 7.43655C19.6031 7.62973 19.4866 8.0593 19.6796 8.39621C22.8874 13.9923 18.7813 20.9146 12.4453 20.8827Z"
                        fill="#3C3C3C"
                      />
                      <path
                        d="M4.03208 17.397C4.22507 17.7337 4.65482 17.8504 4.99174 17.6574C5.32865 17.4642 5.44511 17.0344 5.25193 16.6975C2.06846 11.1439 6.08634 4.211 12.4657 4.211H15.2828L14.2237 5.27191C13.9493 5.54675 13.9496 5.99189 14.2245 6.26636C14.4993 6.54065 14.9446 6.54029 15.2189 6.26544L17.4744 4.0061C17.7502 3.72998 17.7516 3.27167 17.4574 2.99555L15.2189 0.753235C14.9446 0.478577 14.4995 0.477844 14.2245 0.752319C13.9496 1.02679 13.9493 1.47192 14.2236 1.74677L15.2797 2.80475H12.457C5.01994 2.80493 0.305517 10.8958 4.03208 17.397Z"
                        fill="#3C3C3C"
                      />
                      <path
                        d="M13.1689 14.6562H10.3564C9.96826 14.6562 9.65332 14.9712 9.65332 15.3594C9.65332 15.7477 9.96826 16.0625 10.3564 16.0625H11.7627V16.7656C11.7627 17.154 12.0776 17.4688 12.4658 17.4688C12.8542 17.4688 13.1689 17.154 13.1689 16.7656V16.0625C14.3322 16.0625 15.2783 15.1162 15.2783 13.9531C15.2783 12.79 14.3322 11.8437 13.1689 11.8437H11.7629C11.3751 11.8437 11.0596 11.5284 11.0596 11.1406C11.0596 10.7528 11.3732 10.4373 11.7629 10.4373C12.4894 10.4373 12.4658 10.4375 12.4658 10.4375C12.4669 10.4375 12.468 10.4373 12.4691 10.4373C12.4964 10.4373 14.5909 10.4375 14.5752 10.4375C14.9636 10.4375 15.2783 10.1227 15.2783 9.73455C15.2785 9.34619 14.9637 9.03143 14.5754 9.03125H13.1689V8.32812C13.1689 7.93994 12.8542 7.62499 12.4658 7.62499C12.0776 7.62499 11.7627 7.93994 11.7627 8.32812V9.03106C10.597 9.03106 9.65332 9.97424 9.65332 11.1406C9.65332 12.3037 10.5998 13.25 11.7629 13.25H13.1689C13.5568 13.25 13.8721 13.5655 13.8721 13.9531C13.8721 14.3409 13.5568 14.6562 13.1689 14.6562Z"
                        fill="#3C3C3C"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1744_1579">
                        <rect
                          width="24"
                          height="24"
                          fill="white"
                          transform="matrix(-1 0 0 -1 24.5 24.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <Link
                    to="/profile"
                    className="text-[#3C3C3C] tracking-[1.6px] underline cursor-pointer"
                  >
                    MY SUBSCRIPTION
                  </Link>
                </div>

                <div
                  className="flex gap-5"
                  onClick={() => {
                    setUser(null);
                    localStorage.clear();
                  }}
                >
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.4603 10.94H12.5803C13.0203 10.94 13.3803 10.58 13.3803 10.14V3.70002C13.3803 3.26002 13.0203 2.90002 12.5803 2.90002H12.4603C12.0203 2.90002 11.6603 3.26002 11.6603 3.70002V10.1C11.6203 10.58 11.9803 10.94 12.4603 10.94Z"
                      fill="#3C3C3C"
                    />
                    <path
                      d="M17.8202 7.02007C17.5402 6.82007 17.2202 6.78007 16.9002 6.90007C16.5802 7.06007 16.4202 7.34007 16.4202 7.70007C16.4202 7.98007 16.5402 8.22007 16.7402 8.38007C18.5802 9.86007 19.5002 12.1801 19.1802 14.5801C18.7802 17.5801 16.3002 19.9801 13.2602 20.3401C11.3002 20.5401 9.46017 19.9801 7.98017 18.6601C6.54017 17.3801 5.74017 15.5401 5.74017 13.6201C5.74017 11.5801 6.66017 9.66007 8.26017 8.38007C8.46017 8.22007 8.58017 7.98007 8.58017 7.70007C8.58017 7.34007 8.38017 7.06007 8.10017 6.90007C7.98017 6.86007 7.86017 6.82007 7.70017 6.82007C7.50017 6.82007 7.34017 6.90007 7.18017 7.02007C4.90017 8.86007 3.74017 11.6601 4.06017 14.5801C4.50017 18.5001 7.62017 21.6201 11.5402 22.0601C13.9802 22.3401 16.3402 21.5801 18.1802 19.9401C19.9802 18.3401 21.0202 16.0201 21.0202 13.6201C20.9802 11.0201 19.8202 8.62007 17.8202 7.02007Z"
                      fill="#3C3C3C"
                    />
                  </svg>

                  <p className="text-[#3C3C3C] tracking-[1.6px] underline cursor-pointer">
                    LOGOUT
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="flex h-16 items-center justify-between lg:flex-row flex-row-reverse">
          {path.pathname === "/login" ||
          path.pathname === "/register" ||
          path.pathname === "/forget-password" ||
          path.pathname === "/reset-password" ||
          path.pathname === "/verify" ||
          path.pathname === "/payment" ? (
            <></>
          ) : (
            <div className="inset-y-0 left-0 flex items-center lg:hidden">
              {/* <!-- Mobile menu button--> */}
              <button
                id="menuBtn"
                type="button"
                className="relative inline-flex items-center justify-center rounded-md p-2  bg-[#3E5FFF] text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                aria-controls="mobile-menu"
                aria-expanded="false"
                onClick={toggleMenuMob}
              >
                <span className="absolute -inset-0.5"></span>

                <svg
                  className="block h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                  />
                </svg>

                <svg
                  className="hidden h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          )}
          <div className="flex flex-1 items-center justify-start sm:items-stretch sm:justify-between">
            <div className="flex flex-shrink-0 items-center">
              <Link to="/" className="font-bold text-[32px]">
                <span className="text-[#3E5FFF]">9D</span> VPN
              </Link>
            </div>
            <div className="hidden lg:ml-6 lg:block my-auto">
              {path.pathname === "/login" ||
              path.pathname === "/register" ||
              path.pathname === "/forget-password" ||
              path.pathname === "/reset-password" ||
              path.pathname === "/verify" ||
              path.pathname === "/payment" ? (
                <></>
              ) : (
                <div className="flex items-center">
                  <a
                    href="/#home"
                    className="mx-3 px-2 py-3 text-[#0F172A] font-medium hover:text-[#3E5FFF] nav-link"
                  >
                    Home
                  </a>
                  <a
                    href="/#whyUse9dVpn"
                    className="mx-3 px-2 py-3 text-[#0F172A] font-medium hover:text-[#3E5FFF] nav-link"
                  >
                    Why 9D VPN
                  </a>
                  <a
                    href="/#features"
                    className="mx-3 px-2 py-3 text-[#0F172A] font-medium hover:text-[#3E5FFF] nav-link"
                  >
                    Features
                  </a>
                  <a
                    href="/#pricing"
                    className="mx-3 px-2 py-3 text-[#0F172A] font-medium hover:text-[#3E5FFF] nav-link"
                  >
                    Pricing
                  </a>
                  {user ? (
                    <button
                      className="h-10 w-10 rounded-[50%] bg-[#E2E7FF] text-[#3E5FFF] ml-8 p-2"
                      onClick={() => setShowProfileCard(!showProfileCard)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                        className="w-full h-full "
                      >
                        <path
                          fill="#3e5fff"
                          d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM49.3 464H398.7c-8.9-63.3-63.3-112-129-112H178.3c-65.7 0-120.1 48.7-129 112zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3z"
                        />
                      </svg>
                    </button>
                  ) : (
                    <Link
                      to="/login"
                      className="mx-3 px-6 py-3 text-[#FFFFFF] font-medium w-[137px] text-center bg-[#3E5FFF] rounded-[300px]"
                    >
                      Login
                    </Link>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Mobile menu, show/hide based on menu state. --> */}

      {isOpenMenu && (
        <div className="lg:hidden container mx-auto" id="mobile-menu">
          <div className="space-y-1 px-2 pb-3 pt-2">
            <a
              href="/#home"
              className="block rounded-md px-3 py-2 text-base font-medium text-center hover:text-[#3E5FFF] nav-link"
              aria-current="page"
              onClick={handleLinkClick}
            >
              Home
            </a>
            <a
              href="/#whyUse9dVpn"
              className="block rounded-md px-3 py-2 text-base font-medium text-center hover:text-[#3E5FFF] nav-link"
              onClick={handleLinkClick}
            >
              Why 9D VPN
            </a>
            <a
              href="/#features"
              className="block rounded-md px-3 py-2 text-base font-medium text-center hover:text-[#3E5FFF] nav-link"
              onClick={handleLinkClick}
            >
              Features
            </a>
            <a
              href="/#pricing"
              className="block rounded-md px-3 py-2 text-base font-medium text-center hover:text-[#3E5FFF] nav-link"
              onClick={handleLinkClick}
            >
              Pricing
            </a>
            {user ? (
              <>
                <Link
                  to="/profile"
                  className="block rounded-md px-3 py-2 text-base font-medium text-center hover:text-[#3E5FFF] nav-link"
                >
                  Profile
                </Link>
                <button
                  to="/profile"
                  className="block w-full px-3 py-2 text-base font-medium text-center text-[#FFFFFF] bg-[#3E5FFF] rounded-[300px]"
                  onClick={() => {
                    setUser(null);
                    localStorage.clear();
                  }}
                >
                  Logout
                </button>
              </>
            ) : (
              <Link
                to="/login"
                className="block px-3 py-2 text-base font-medium text-center text-[#FFFFFF] bg-[#3E5FFF] rounded-[300px]"
              >
                Login
              </Link>
            )}
          </div>
        </div>
      )}
    </nav>
  );
}

export default Navbar;
