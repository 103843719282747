import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import Loader from "../components/loader/Loader";

function ResetPass() {
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [passType, setPassType] = useState("password");
  const [cPassType, setCPassType] = useState("password");
  const navigate = useNavigate();

  const {setError, isLoading, error, resetPassword} = useAuth()

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confPassword) {
      setError("password didn't match");
      return;
    }
    const email = localStorage.getItem("email")
    await resetPassword(email, password, confPassword)
  };


  useEffect(()=>{
    const forgetTrue = localStorage.getItem("email")
    console.log(forgetTrue);
    if(!forgetTrue){
      navigate("/forget-password")
      return
    }
  },[])

  return (
    <>
     {isLoading && <Loader />}
      <div className="bg-gradient-to-r from-[#F1F5F9] to-[#FFFFFF] w-full h-[calc(100vh-161px)] py-16">
        <div className="flex flex-col items-center justify-center w-full h-full">
          
            <div className=" w-full md:w-[600px] rounded-[12px] border-[1px] border-[#ECECEC] px-5 py-[50px] sm:px-[50px] bg-white shadow ">
              <div className="flex flex-col">
                <Link
                  to="/login"
                  className="flex items-center gap-3 text-[#3E5FFF] underline cursor-pointer mb-5"
                >
                  <svg
                    width="10"
                    height="17"
                    viewBox="0 0 10 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.6582 1.33325L1.07925 8.36103L8.6582 15.3888"
                      stroke="#3E5FFF"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <p>Back</p>
                </Link>
                <div className="mb-[30px]">
                  <h1 className="text-[#0F172A] text-[40px] font-bold ">
                    Reset Password
                  </h1>
                </div>

                <form className="w-full gap-[10px]" onSubmit={handleSubmit}>
                  <label htmlFor="" className="text-[#3C3C3C]">
                    {" "}
                    Enter your new password
                  </label>
                  <div className="flex relative mt-2">
                    <input
                      className={`w-full h-[60px] bg-[#F7F9FD] text-[#989898] text-sm rounded-[12px] px-[20px] py-[10px] mb-[16px]`}
                      type={passType}
                      required
                      placeholder="Password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    {passType === "text" ? (
                      <img
                        className="absolute top-[20px] right-[20px] pointer h-6 w-6 "
                        src="./images/eye-2.svg"
                        alt=""
                        onClick={() =>
                          passType === "password"
                            ? setPassType("text")
                            : setPassType("password")
                        }
                      />
                    ) : (
                      <img
                        className="absolute top-[20px] right-[20px] pointer h-6 w-6"
                        src="./images/eye.svg"
                        alt=""
                        onClick={() =>
                          passType === "password"
                            ? setPassType("text")
                            : setPassType("password")
                        }
                      />
                    )}
                  </div>
                  <div className="flex relative">
                    <input
                      className={`w-full h-[60px] bg-[#F7F9FD] text-[#989898] text-sm rounded-[12px] px-[20px] py-[10px] mb-2`}
                      type={cPassType}
                      required
                      placeholder="Confirm password"
                      onChange={(e) => setConfPassword(e.target.value)}
                    />
                    {cPassType === "text" ? (
                      <img
                        className="absolute top-[20px] right-[20px] pointer h-6 w-6 "
                        src="./images/eye-2.svg"
                        alt=""
                        onClick={() =>
                          cPassType === "password"
                            ? setCPassType("text")
                            : setCPassType("password")
                        }
                      />
                    ) : (
                      <img
                        className="absolute top-[20px] right-[20px] pointer h-6 w-6"
                        src="./images/eye.svg"
                        alt=""
                        onClick={() =>
                          cPassType === "password"
                            ? setCPassType("text")
                            : setCPassType("password")
                        }
                      />
                    )}
                  </div>
                  <p
                    className={`text-[#F14950] mb-4 ${
                      error ? "visible" : "invisible"
                    }`}
                  >
                    {error}
                  </p>

                  <div>
                    <button className="w-full h-[60px] bg-[#3E5FFF] text-[#ffffff] text-lg font-bold rounded-[12px] px-[20px] py-[10px]">
                      Save & Done
                    </button>
                  </div>
                </form>
              </div>
            </div>


          {/* {tokenExpire && (
            <div className=" w-full md:w-[600px]  rounded-[12px] border-[1px] border-[#ECECEC] px-5 py-[50px] sm:px-[50px] bg-white shadow ">
              <div className="flex flex-col gap-4">
                <div>
                  <img
                    src="./images/link-expire.svg"
                    alt=""
                    className="mx-auto"
                  />
                </div>
                <div className="mb-[30px]">
                  <h1 className="text-[#0F172A] text-[40px] font-bold text-center">
                    Link Expired!
                  </h1>
                  <p className="text-center">
                    Your link has expired. Resend a new link to your email for
                    password reset and regain access to your account.
                  </p>
                </div>
                <div>
                  <button
                    className="w-full h-[60px] bg-[#3E5FFF] text-[#ffffff] text-lg font-bold rounded-[12px] px-[20px] py-[10px]"
                    onClick={handleResendEmail}
                  >
                    Resend Link
                  </button>
                </div>
              </div>
            </div>
          )}

          {showCheckMail && <CheckEmailBox email={"inam@inam.com"} />} */}
        </div>
      </div>
    </>
  );
}

export default ResetPass;
