import React, { useEffect } from "react";
import PricingPlan from "../components/PricingPlan";
import WhyUse from "../components/WhyUse";
import Aos from "aos";
import "aos/dist/aos.css";
function DesktopPricing() {
    const priceFor= "Desktop & Extension"

    useEffect(() => {
        window.scrollTo(0, 0);
        Aos.init({
          duration: 0,
          easing: "ease-in",
          once: true,
        });
      }, []);
  return (
    <>
      <div className="bg-gradient-to-b from-[#F1F5F9] to-[#FFFFFF00] py-[65px]">
        <PricingPlan priceFor={priceFor} />
      </div>

      <WhyUse/>
    </>
  );
}

export default DesktopPricing;
