import React, { useEffect } from "react";
import Hero from "../components/Hero";
import WhyUse from "../components/WhyUse";
import Features from "../components/Features";
import Pricing from "../components/Pricing";
import FeedbackReviews from "../components/FeedbackReviews";
import Aos from "aos";
import "aos/dist/aos.css";
import OtherProducts from "../components/OtherProducts";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
    Aos.init({
      duration: 0,
      easing: "ease-in",
      once: true,
    });
  }, []);

  return (
    <>
      <Hero />
      <WhyUse />
      <Features />
      <Pricing />
      <OtherProducts />
      <FeedbackReviews />
    </>
  );
}

export default Home;
