import { Player } from "@lottiefiles/react-lottie-player";
import React from "react";
import { Link } from "react-router-dom";
function Hero() {
  const platforms = [
    {
      path: "/downloads/windows",
      src: "./images/platforms/windows.svg",
      alt: "Windows",
    },
    { path: "/downloads/mac", src: "./images/platforms/mac.svg", alt: "Mac" },
    {
      path: "/downloads/android",
      src: "./images/platforms/android.svg",
      alt: "Android",
    },
    {
      path: "/downloads/linux",
      src: "./images/platforms/linux.svg",
      alt: "Linux",
    },
    { path: "/downloads/ios", src: "./images/platforms/ios.svg", alt: "iOS" },
    {
      path: "/downloads/chrome",
      src: "./images/platforms/chrome.svg",
      alt: "Chrome",
    },
  ];

  return (
    <section
      id="home"
      data-aos="fade-down"
      data-aos-duration="500"
      data-aos-once="true"
      className="pt-[20px]"
    >
      <div className="container mx-auto h-fit py-[80px]">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-[24px]">
          <div className="flex flex-col item-start justify-center">
            <div className="flex items-center">
              <h1 className="text-[45px] sm:text-[60px] text-center lg:text-left font-bold leading-[66px] relative inline-block 2xl:w-[80%]">
                Tap Once for a Quick
                <span className="hidden sm:block hero-ring z-[-10]"></span>
                and Safe Internet
              </h1>
            </div>
            <p className="text-[18px] font-normal leading-[28px] mt-[24px] 2xl:w-[80%] text-center lg:text-left">
              With our VPN, you can browse the internet confidently, knowing
              your information is secure and protected, no matter where you are
              online.
            </p>

            <div className="flex justify-center flex-wrap lg:justify-start gap-8 my-12">
              {platforms.map((platform, index) => (
                <Link key={index} to={platform.path}>
                  <img src={platform.src} alt={platform.alt} />
                </Link>
              ))}
            </div>

            <div className="flex items-center flex-col md:flex-row gap-4">
              <a className="py-[25px] w-[241px] bg-[#2563EB] rounded-[300px] text-[20px] text-[#FFFFFF] text-center font-bold  mx-auto lg:mx-0">
                GET IT NOW
              </a>
            </div>
          </div>
          <div className="h-[627px]">
            <div className="flex justify-center items-center h-full relative">
              <Player
                src="animation/Mockup Design.json"
                background="transparent"
                speed="1"
                class="w-[340px] sm:w-[450px] md:w-[550px] h-[450px] md:h-[550px] z-[-10] absolute"
                loop
                autoplay
                data-aos="zoom-in"
                data-aos-duration="1500"
                data-aos-once="true"
              ></Player>

              <img
                src="images/Ellipse 21.png"
                alt=""
                className=" absolute"
                data-aos="zoom-in"
                data-aos-duration="1500"
                data-aos-once="true"
              />
              <img
                src="images/hero_img.png"
                alt=""
                className="w-[200px] sm:w-[280px] md:w-[308px] absolute"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
