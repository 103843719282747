import Aos from "aos";
import React, { useEffect } from "react";

function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
    Aos.init({
      duration: 1000,
      easing: "ease-in",
      once: true,
    });
  }, []);
  return (
    <div className="container mx-auto py-[70px] text-justify">
      <h1 className="text-[40px] text-center mb-[20px]">
        Privacy Policy 9D VPN
      </h1>

      <div className="container px-[40px]">
        <p className="my-[10px]">
          <span className="font-bold">Effective Date:</span> 07-Aug-2023
        </p>

        <p className="my-[10px]">
          At 9D TECHNOLOGIES, we offer the 9D VPN app as a Free service. We are
          committed to protecting your privacy.We want you to understand what
          information we collect, what we don't collect, and how we collect,
          use, and store information. We do not collect logs of your activity,
          including no logging of browsing history, traffic destination, data
          content, or DNS queries. We also never store connection logs, i.e., no
          logs of your IP address, your outgoing connection timestamp, or
          session duration. We designed our systems not to have sensitive data
          about you, By using the app, you agree to the collection of apps
          analytics data as described in this policy.
        </p>

        <h2 className="text-[20px] font-bold my-[10px]">
          Apps Offering VPN Services:
        </h2>
        <p className="my-[10px]">
          Our 9D VPN app utilizes the NEVPNManager API for VPN services, in
          compliance with Apple's guidelines. Additionally, the app is offered
          exclusively by 9D TECHNOLOGIES, duly enrolled organization in the
          Apple Developer Program.
        </p>
        <p className="my-[10px]">
          This service is provided exclusively to persons. Accounts registered
          by bots or automated methos are not authorized and will be terminated.
        </p>

        <h2 className="text-[20px] font-bold my-[10px]">
          Data Collection and Use (GDPR, CCPA, and CPRA Compliance):
        </h2>
        <p className="my-[10px]">
          In compliance with the GDPR, CCPA, and CPRA, we affirmatively state
          that we do not collect any user data related to identity or
          behavior.We operate with a zero-log policy, meaning we do not collect
          any personally identifiable information or cookies in our app.
          However, we use the following well-known SDKs, Firebase and Google
          Analytics, to enhance app functionality, identify app crashes, ANRs,
          and improve overall app performance. These SDKs may collect anonymized
          app usage data to provide us with insights for better service
          optimization. The data collected is solely used for app functionality,
          personalization, and analytics, and not for tracking users across apps
          or websites or displaying advertisements.
        </p>

        <h2 className="text-[20px] font-bold my-[10px]">VPN Service:</h2>
        <p className="my-[10px]">
          Our 9D VPN app encrypts user traffic using the OpenVPN and WireGuard
          protocols, which are well-known and secure protocols available on the
          Apple Store. These protocols are compiled in compliance with Apple's
          standards and policies to ensure secure and fast VPN connections.
        </p>

        <h2 className="text-[20px] font-bold my-[10px]">
          Declaration of Data Collection:
        </h2>
        <p className="my-[10px]">
          We do not collect any user data related to identity or behaviour, and
          we affirmatively state this in our privacy policy. The data collected
          from users is limited to the minimal information required for app
          functionality, and users are not obligated to provide any data for the
          app to function correctly.
        </p>

        <h2 className="text-[20px] font-bold my-[10px]">Children's Privacy:</h2>
        <p className="my-[10px]">
          The protection of your privacy and that of your children is of utmost
          importance to us. We strictly adhere to Apple guidelines, and we want
          to assure you that we do not knowingly collect personally identifiable
          information from children under 13 years old. Our commitment to your
          privacy goes beyond that – we do not offer any options or features
          that would intentionally or unintentionally allow any user to share
          their data with us except minimal data about app analytics as
          discussed above . Our zero-log policy ensures that we never collect
          any data related to your identity or any of our users' identities. Our
          app's primary purpose is to provide a secure solution for you and your
          children, protecting you from hackers and online intruders while using
          the internet, without compromising your data in any way.
        </p>

        <h2 className="text-[20px] font-bold my-[10px]">
          App Privacy Section:
        </h2>
        <p className="my-[10px]">
          Please note that the App Privacy section may not include all data
          collection practices as we follow a zero-log policy, and our app does
          not collect any personally identifiable information or user behaviour
          data.we are showing a privacy screen when our app starts without
          agreeing with our privacy policy no one can access our app.
        </p>

        <h2 className="text-[20px] font-bold my-[10px]">
          Additional Resources:
        </h2>
        <h3 className="font-bold my-[5px]">WireGuard Privacy Policy</h3>
        <a
          href="https://www.wireguard.com/privacy-policy-app.txt"
          target="_blank"
          className="text-[#3E5FFF]"
        >
          Read More
        </a>
        <h3 className="font-bold my-[5px]">OpenVpn Privacy Policy</h3>
        <a
          href="https://openvpn.net/privacy-policy/"
          target="_blank"
          className="text-[#3E5FFF]"
        >
          Read More
        </a>
        <h3 className="font-bold my-[5px]">
          Firebase analytics Privacy Policy
        </h3>
        <a
          href="https://firebase.google.com/support/privacy/"
          target="_blank"
          className="text-[#3E5FFF]"
        >
          Read More
        </a>
        <h3 className="font-bold my-[5px]">Google Analytics Privacy Policy</h3>
        <a
          href="https://firebase.google.com/policies/analytics/"
          target="_blank"
          className="text-[#3E5FFF]"
        >
          Read More
        </a>

        <h3 className="font-bold my-[5px]">Facebook Privacy Policy</h3>
        <a
          href="https://www.facebook.com/legal/terms/plain_text_terms"
          target="_blank"
          className="text-[#3E5FFF]"
        >
          Read More
        </a>

        <h2 className="text-[20px] font-bold my-[10px]">Contact Us:</h2>
        <p className="my-[10px]">
          If you have any questions or suggestions regarding our Privacy Policy,
          please don't hesitate to contact us at
          <a
            href="info@9dtechnologies.com"
            className="text-[#3E5FFF]"
            target="_blank"
          >
            info@9dtechnologies.com
          </a>
          Or visit official Support website link
          <a
            href="  https://www.9dtechnologies.com/contact-us/"
            className="text-[#3E5FFF]"
            target="_blank"
          >
            https://www.9dtechnologies.com/contact-us/
          </a>
        </p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
