import React from "react";
import PricingPlan from "./PricingPlan";

function Pricing() {
  const features = [
    " VPN servers worldwide",
    "AES 128-bit encryption",
    "DNS and IP leak protection",
    "Strict No Logs Policy",
    "Unlimited bandwidth and traffic",
    "Highest possible VPN speeds",
    "24/7 live Customer Support service",
    "20-day money back guarantee",
  ];

  const priceFor = "Mobile App";
  return (
    <section
      id="pricing"
      className="bg-gradient-to-b from-[#F1F5F9] to-[#FFFFFF00] py-[120px]"
      data-aos="fade-down"
      data-aos-duration="500"
      data-aos-once="true"
    >
      <div className="container px-[24px] mx-auto">
        <div className="flex items-center justify-center">
          <h1 className="font-bold text-[40px] lg:text-[56px] text-center text-[#3C3C3C] relative inline-block">
            All Package Includes
            <span className="hidden sm:block heading-ring"></span>
          </h1>
        </div>
        <div className="container xl:w-[70%] mx-auto my-[50px]">
          <ul className="flex flex-wrap">
            {features.map((feature, idx) => (
              <li key={idx} className="sm:ml-[15%] w-full md:w-[35%] mb-10">
                <div className="w-full flex gap-2">
                  <img src="images/check.svg" alt="" className="inline" />
                  <span>{feature}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>

        <PricingPlan priceFor={priceFor} />
      </div>
    </section>
  );
}

export default Pricing;
