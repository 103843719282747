import React from "react";

function FeedbackReviews() {
  const userReviews = [
    {
      profileImg: "/images/rate-ava-1.png.png",
      username: "Deon Granwitz",
      rating: 5,
      review:
        "I've been using this VPN app for a few months now, and I'm thoroughly impressed. It's incredibly easy to use, and the  connection speed is excellent. Whether I'm browsing the web or streaming content, I feel confident knowing my data is secure. Highly recommend it!",
    },
    {
      profileImg: "/images/rate-ava-2.png.png",
      username: "Syabnrizal",
      rating: 5,
      review:
        "As someone who travels frequently for work, having a reliable VPN is essential. This app has been a lifesaver for me. It's reliable, fast, and has servers all over the world, allowing me to access geo-restricted content with ease. Plus, the customer support is top-notch. Definitely worth every penny!",
    },
    {
      profileImg: "/images/rate-ava-3.png.png",
      username: "Capian Rexth",
      rating: 5,
      review:
        "I was skeptical about using a VPN at first, but after trying out this app, I'm a believer. Not only does it protect my online privacy, but it also gives me peace of mind knowing my sensitive information is safe from prying eyes. Overall, I'm extremely satisfied with my experience.",
    },
  ];

  const storeRatings = [
    {
      storeName: "App Store",
      stars: 5,
      avgRating: 4.6,
    },
    {
      storeName: "Google Play",
      stars: 5,
      avgRating: 4.5,
    },
    {
      storeName: "Chrome Extension",
      stars: 5,
      avgRating: 4.6,
    },
  ];

  return (
    <div
      id="feedback"
      className="bg-gradient-to-b from-[#F1F5F9] to-[#FFFFFF00] py-[120px]"
      data-aos="fade-down"
      data-aos-duration="500"
      data-aos-once="true"
    >
      <div className="container px-[24px] mx-auto h-fit">
        <div className="flex items-center justify-center">
          <h1 className="font-bold text-[40px] lg:text-[56px] text-center text-[#3C3C3C] relative inline-block">
            What Other People are Saying
            <span className="hidden sm:block heading-ring"></span>
          </h1>
        </div>

        <div className="mt-[72px] mb-[120px]">
          <div className="flex items-center justify-between flex-col lg:flex-row">
            {userReviews.map((reviewItem, idx) => (
              <div
                key={idx}
                className="px-[32px] py-[40px] bg-[#ffffff] rounded-[20px] lg:h-[414px] w-full lg:w-[300px] xl:w-[360px] mb-[20px]"
              >
                <div className="flex items-center mb-5">
                  <div className="flex-[0.5]">
                    <img src={reviewItem.profileImg} alt="" />
                  </div>
                  <div className="flex-1">
                    <h4 className="text-[24px] text-[#000000]">
                      {reviewItem.username}
                    </h4>
                    <div className="flex">
                      {[...Array(reviewItem.rating)].map((_, starIndex) => (
                        <img
                          key={starIndex}
                          className="mr-[4px]"
                          src="images/star-icon.svg.svg"
                          alt=""
                        />
                      ))}
                    </div>
                  </div>
                </div>

                <div>
                  <p className="text-[#3C3C3C] leading-[26px]">
                    {reviewItem.review}
                  </p>
                </div>
              </div>
            ))}
            {/* <div className="px-[32px] py-[40px] bg-[#ffffff] rounded-[20px] lg:h-[414px] w-full lg:w-[300px] xl:w-[360px] mb-[20px]">
              <div className="flex items-center mb-5">
                <div className="flex-[0.5]">
                  <img src="images/rate-ava-1.png.png" alt="" />
                </div>
                <div className="flex-1">
                  <h4 className="text-[24px] text-[#000000]">Deon Granwitz</h4>
                  <div className="flex">
                    <img
                      className="mr-[4px]"
                      src="images/star-icon.svg.svg"
                      alt=""
                    />
                    <img
                      className="mr-[4px]"
                      src="images/star-icon.svg.svg"
                      alt=""
                    />
                    <img
                      className="mr-[4px]"
                      src="images/star-icon.svg.svg"
                      alt=""
                    />
                    <img
                      className="mr-[4px]"
                      src="images/star-icon.svg.svg"
                      alt=""
                    />
                    <img
                      className="mr-[4px]"
                      src="images/star-icon.svg.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div>
                <p className="text-[#3C3C3C] leading-[26px]">
                  I've been using this VPN app for a few months now, and I'm
                  thoroughly impressed. It's incredibly easy to use, and the
                  connection speed is excellent. Whether I'm browsing the web or
                  streaming content, I feel confident knowing my data is secure.
                  Highly recommend it!
                </p>
              </div>
            </div>

            <div className="px-[32px] py-[40px] bg-[#ffffff] rounded-[20px] lg:h-[414px] w-full lg:w-[300px] xl:w-[360px] mb-[20px]">
              <div className="flex items-center mb-5">
                <div className="flex-[0.5]">
                  <img src="images/rate-ava-2.png.png" alt="" />
                </div>
                <div className="flex-1">
                  <h4 className="text-[24px] text-[#000000]">Syabnrizal</h4>
                  <div className="flex">
                    <img
                      className="mr-[4px]"
                      src="images/star-icon.svg.svg"
                      alt=""
                    />
                    <img
                      className="mr-[4px]"
                      src="images/star-icon.svg.svg"
                      alt=""
                    />
                    <img
                      className="mr-[4px]"
                      src="images/star-icon.svg.svg"
                      alt=""
                    />
                    <img
                      className="mr-[4px]"
                      src="images/star-icon.svg.svg"
                      alt=""
                    />
                    <img
                      className="mr-[4px]"
                      src="images/star-icon.svg.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div>
                <p className="text-[#3C3C3C] leading-[26px]">
                  As someone who travels frequently for work, having a reliable
                  VPN is essential. This app has been a lifesaver for me. It's
                  reliable, fast, and has servers all over the world, allowing
                  me to access geo-restricted content with ease. Plus, the
                  customer support is top-notch. Definitely worth every penny!
                </p>
              </div>
            </div>

            <div className="px-[32px] py-[40px] bg-[#ffffff] rounded-[20px] lg:h-[414px] w-full lg:w-[300px] xl:w-[360px] mb-[20px]">
              <div className="flex items-center mb-5">
                <div className="flex-[0.5]">
                  <img src="images/rate-ava-3.png.png" alt="" />
                </div>
                <div className="flex-1">
                  <h4 className="text-[24px] text-[#000000]">Capian Rexth</h4>
                  <div className="flex">
                    <img
                      className="mr-[4px]"
                      src="images/star-icon.svg.svg"
                      alt=""
                    />
                    <img
                      className="mr-[4px]"
                      src="images/star-icon.svg.svg"
                      alt=""
                    />
                    <img
                      className="mr-[4px]"
                      src="images/star-icon.svg.svg"
                      alt=""
                    />
                    <img
                      className="mr-[4px]"
                      src="images/star-icon.svg.svg"
                      alt=""
                    />
                    <img
                      className="mr-[4px]"
                      src="images/star-icon.svg.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div>
                <p className="text-[#3C3C3C] leading-[26px]">
                  I was skeptical about using a VPN at first, but after trying
                  out this app, I'm a believer. Not only does it protect my
                  online privacy, but it also gives me peace of mind knowing my
                  sensitive information is safe from prying eyes. Overall, I'm
                  extremely satisfied with my experience.
                </p>
              </div>
            </div> */}
          </div>
          <div className="flex items-center justify-center gap-[5px] my-[30px]">
            <div className="w-[8px] h-[8px] bg-[#FEC737] hover:bg-[#FEC737] rounded-[50%]"></div>
            <div className="w-[8px] h-[8px] bg-[#FE573F4D] hover:bg-[#FEC737] rounded-[50%]"></div>
            <div className="w-[8px] h-[8px] bg-[#FE573F4D] hover:bg-[#FEC737] rounded-[50%]"></div>
          </div>
        </div>

        <div className="mt-[72px]">
          <h3 className="text-[#0F172A] text-[35px] sm:text-[40px] text-center mb-[50px]">
            People around the world are raving about 9D VPN
          </h3>
          <div className="flex items-center justify-between flex-col md:flex-row mx-auto">
            {storeRatings.map((storeitem, idx) => (
              <div
                key={idx}
                className="flex items-center flex-col lg:flex-row mb-[20px]"
              >
                <span className="font-medium text-[24px] mr-[20px]">
                  {storeitem.storeName}
                </span>
                <div className="flex items-center">
                  <div className="flex">
                    {[...Array(storeitem.stars)].map((_, starIndex) => (
                      <img
                        key={starIndex}
                        className="mr-[4px] w-[21px] h-[21px]"
                        src="images/star-icon.svg.svg"
                        alt=""
                      />
                    ))}
                  </div>
                  <span className="text-[#3C3C3C] text-[24px]">
                    {storeitem.avgRating}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeedbackReviews;
