import React from "react";
import { Link } from "react-router-dom";

function OtherProducts() {
  const features = [
    "VPN servers worldwide",
    "Highest possible VPN speeds",
    "20-day money back guarantee",
    "kill switch",
    "Unlimited bandwidth and traffic",
    "24/7 Live Support",
  ];
  return (
    <div
      id="feedback"
      className="bg-gradient-to-b from-[#F1F5F9] to-[#FFFFFF00] py-[120px]"
      data-aos="fade-down"
      data-aos-duration="500"
      data-aos-once="true"
    >
      <div className="container px-[24px] mx-auto h-fit">
        <div className="flex items-center justify-center">
          <h1 className="font-bold text-[40px] lg:text-[56px] text-center text-[#3C3C3C] relative inline-block">
            Desktop & Extension
            <span className="hidden sm:block heading-ring"></span>
          </h1>
        </div>

        <div className="flex items-center justify-center my-[90px] w-full  ">
          <ul className="flex flex-wrap">
            {features.map((feature, idx) => (
              <li key={idx} className="sm:ml-[15%] w-full md:w-[35%] mb-10">
                <div className="w-full flex gap-2">
                  <svg
                    width="23"
                    height="21"
                    viewBox="0 0 23 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.5234 0.84375C19.8047 0.84375 22.5 3.5 22.5 6.78125V9.90625C22.5 10.4531 22.0703 10.8438 21.5625 10.8438H20.3125C19.7656 10.8438 19.375 10.4531 19.375 9.90625V6.78125C19.375 5.25781 18.0859 3.96875 16.5234 3.96875C14.9609 4.00781 13.75 5.29688 13.75 6.82031V9.59375H15.625C16.6406 9.59375 17.5 10.4531 17.5 11.4688V18.9688C17.5 20.0234 16.6406 20.8438 15.625 20.8438H1.875C0.820312 20.8438 0 20.0234 0 18.9688V11.4688C0 10.4531 0.820312 9.59375 1.875 9.59375H10.625V6.85938C10.625 3.57812 13.2422 0.882812 16.5234 0.84375Z"
                      fill="#FF9A06"
                    />
                  </svg>
                  <span>{feature}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>

        <Link
          to="/desktop-plans"
          className="block w-[270px] sm:w-[350px] md:w-[429px] px-[24px] py-[14px] bg-[#2563EB] rounded-[300px] text-[24px] text-[#FFFFFF] text-center font-bold mx-auto mt-[30px]"
        >
          View Plans
        </Link>
      </div>
    </div>
  );
}

export default OtherProducts;
