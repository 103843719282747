import React, { useEffect } from "react";
import { decodeToken } from "react-jwt";
import { useNavigate, useSearchParams } from "react-router-dom";

function PaymentCancel() {
  const [searchParams] = useSearchParams();
  const platform = searchParams.get("platform");
  const navigate = useNavigate();

  useEffect(() => {
    const user = decodeToken(localStorage.getItem("token"));
    if (!user || !searchParams || !platform) {
      navigate("/");
      return;
    }
  }, []);

  return (
    <>
      <div className="bg-gradient-to-r from-[#F1F5F9] to-[#FFFFFF] w-full py-16 h-[calc(100vh-281px)]">
        <div className="flex flex-col items-center justify-center w-full h-full">
          <div className=" w-full md:w-[660px]  rounded-[12px] border-[1px] border-[#ECECEC] px-5 py-[50px] sm:px-[50px] bg-white shadow ">
            <div className="flex flex-col gap-10">
              <h1 className="text-[40px] font-bold">Subscription Snag!!</h1>
              <p className="leading-8">
                We hit a snag updating your subscription. Not to worry, it might
                just be a glitch or something small. Double-check your payment
                info, or give it another try. Stuck? Our support team's got your
                back!
              </p>
              <button className="w-[489px] bg-[#3E5FFF] text-[#ffffff] text-[24px] font-bold rounded-[300px] px-[20px] py-[18px]">
                Go to Dashboard
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentCancel;
