import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import Loader from "../components/loader/Loader";

function ForgetPass() {
  const [email, setEmail] = useState("");

  const { forgetPassword, isLoading, error, setError } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    await forgetPassword(email);
  };

  useEffect(() => {
    setError(null)
  }, []);

  return (
    <>
    {isLoading && <Loader />}
      <div className="bg-gradient-to-r from-[#F1F5F9] to-[#FFFFFF] w-full h-[calc(100vh-161px)]">
        <div className="flex flex-col items-center justify-center w-full h-full">
          {/* {error ? ( */}
            <div className="w-full md:w-[600px] rounded-[12px] border-[1px] border-[#ECECEC] px-5 py-[50px] sm:px-[50px] bg-white shadow">
              <div className="flex flex-col">
                <Link
                  to="/login"
                  className="flex items-center gap-3 text-[#3E5FFF] underline cursor-pointer mb-5"
                >
                  <svg
                    width="10"
                    height="17"
                    viewBox="0 0 10 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.6582 1.33325L1.07925 8.36103L8.6582 15.3888"
                      stroke="#3E5FFF"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <p>Back</p>
                </Link>

                <div className="mb-[30px]">
                  <h1 className="text-[#222B42] text-[40px] font-bold ">
                    Reset Password
                  </h1>
                </div>

                <form className="w-full " onSubmit={handleSubmit}>
                  <label htmlFor="" className="text-[#3C3C3C]">
                    {" "}
                    Enter the email address
                  </label>
                  <input
                    className={`w-full h-[60px] bg-[#F7F9FD] text-[#989898] text-sm rounded-[12px] px-[20px] py-[10px] my-2 ${
                      error && "border-[1px] border-[#F14950]"
                    }`}
                    type="email"
                    required
                    placeholder="Email address"
                    onChange={(e) => setEmail(e.target.value)}
                  />

                  <p
                    className={`text-[#F14950] mb-4 ${
                      error ? "visible" : "invisible"
                    }`}
                  >
                    {error}
                  </p>

                  <div>
                    <button className="w-full h-[60px] bg-[#3E5FFF] text-[#ffffff] text-lg font-bold rounded-[300px] px-5 py-[10px]">
                    Get Verification Code
                    </button>
                  </div>
                </form>
              </div>
            </div>
          {/* ) : ( */}
            {/* <CheckEmailBox email={email} /> */}
          {/* )} */}
        </div>
      </div>
    </>
  );
}

export default ForgetPass;
