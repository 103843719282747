import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import Loader from "../components/loader/Loader";

function Signup() {
  const [email, setEmail] = useState("");
  const [fullname, setFullname] = useState("");
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [passType, setPassType] = useState("password");
  const [cPassType, setCPassType] = useState("password");

  const navigate = useNavigate();

  const { signup, user, error, isLoading } = useAuth();

  const handleSignup = async (e) => {
    e.preventDefault();

    await signup(fullname, email, password, confPassword);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/");
    }
  }, [user]);

  return (
    <>
     {isLoading && <Loader />}
      <div className="bg-gradient-to-r from-[#F1F5F9] to-[#FFFFFF] w-full py-16">
        <div className="flex flex-col items-center justify-center w-full h-full">
          <div className=" w-full md:w-[600px]  rounded-[12px] border-[1px] border-[#ECECEC] px-5 py-[50px] sm:px-[50px] bg-white shadow ">
            <div className="flex flex-col">
              <div className="mb-[30px]">
                <h1 className="text-[#0F172A] text-[40px] font-bold ">
                  Sign Up
                </h1>
                <p className="text-[#3C3C3C]  mt-[10px]">
                  Already have an account?{" "}
                  <Link to="/login" className="text-[#3E5FFF]">
                    Login
                  </Link>
                </p>
              </div>

              <form className="w-full gap-[10px]" onSubmit={handleSignup}>
                <input
                  className={`w-full h-[60px] bg-[#F7F9FD] text-[#989898] text-sm rounded-[12px] px-[20px] py-[10px] mb-2 `}
                  type="email"
                  required
                  placeholder="Email address"
                  onChange={(e) => setEmail(e.target.value)}
                />

                <input
                  className="w-full h-[60px] bg-[#F7F9FD] text-[#989898] text-sm rounded-[12px] px-[20px] py-[10px] mb-[16px]"
                  type="text"
                  required
                  placeholder="Full Name"
                  onChange={(e) => setFullname(e.target.value)}
                />
                <div className="flex relative">
                  <input
                    className={`w-full h-[60px] bg-[#F7F9FD] text-[#989898] text-sm rounded-[12px] px-[20px] py-[10px] mb-[16px]`}
                    type={passType}
                    required
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {passType === "text" ? (
                    <img
                      className="absolute top-[20px] right-[20px] pointer h-6 w-6 "
                      src="./images/eye-2.svg"
                      alt=""
                      onClick={() =>
                        passType === "password"
                          ? setPassType("text")
                          : setPassType("password")
                      }
                    />
                  ) : (
                    <img
                      className="absolute top-[20px] right-[20px] pointer h-6 w-6"
                      src="./images/eye.svg"
                      alt=""
                      onClick={() =>
                        passType === "password"
                          ? setPassType("text")
                          : setPassType("password")
                      }
                    />
                  )}
                </div>
                <div className="flex relative">
                  <input
                    className={`w-full h-[60px] bg-[#F7F9FD] text-[#989898] text-sm rounded-[12px] px-[20px] py-[10px] mb-2`}
                    type={cPassType}
                    required
                    placeholder="Confirm password"
                    onChange={(e) => setConfPassword(e.target.value)}
                  />
                  {cPassType === "text" ? (
                    <img
                      className="absolute top-[20px] right-[20px] pointer h-6 w-6 "
                      src="./images/eye-2.svg"
                      alt=""
                      onClick={() =>
                        cPassType === "password"
                          ? setCPassType("text")
                          : setCPassType("password")
                      }
                    />
                  ) : (
                    <img
                      className="absolute top-[20px] right-[20px] pointer h-6 w-6"
                      src="./images/eye.svg"
                      alt=""
                      onClick={() =>
                        cPassType === "password"
                          ? setCPassType("text")
                          : setCPassType("password")
                      }
                    />
                  )}
                </div>
                <p
                  className={`text-[#F14950] mb-4 ${
                    error ? "visible" : "invisible"
                  }`}
                >
                  {error}
                </p>

                <div>
                  <button className="w-full h-[60px] bg-[#3E5FFF] text-[#ffffff] text-lg font-bold rounded-[300px] px-[20px] py-[10px]">
                    {isLoading ? "Signing Up" : "Sign Up"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Signup;
