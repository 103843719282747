import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import newRequest from "../utils/newRequest";
function PricingPlan({ priceFor }) {
  const [packages, setPackages] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState("basic");
  const { user } = useAuth();
  const navigate = useNavigate();

  const features = [
    {
      icon: "images/speed.svg",
      text: "High Speed",
    },
    {
      icon: "images/no-logs.svg",
      text: "No-logs policy",
    },
    {
      icon: "images/mult-servers.svg",
      text: "Multiple servers",
    },
  ];

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        let allPackages;
        if (!localStorage.getItem("packages")) {
          const response = await newRequest.get("/packages");
          let allPackages = response.data.packages.sort((a,b)=> a.id-b.id);
          localStorage.setItem("packages", JSON.stringify(allPackages));
        } else {
          allPackages = localStorage.getItem("packages");
          allPackages = JSON.parse(allPackages).sort((a,b)=> a.id-b.id);
        }
        const filteredPackages = allPackages.filter((myPackage) => {
          if (priceFor === "Desktop & Extension") {
            return myPackage.platform === "desktop";
          }
          return myPackage.platform === "mobile";
        });
        setPackages(filteredPackages);
      } catch (error) {
        console.log(error);
      }
    };

    fetchPackages();
  }, []);

  const handlePayment = (e) => {
    if (!user) {
      navigate("/login");
      return;
    }

    // payment integration
    const makePayment = async () => {
      const stripe = await loadStripe(
        "pk_test_51PB8oPP6VbxzdR7EEASzVOKFNZHki6K3fr57jevlHps8QGd3AQ3loKmO2xqlHXUXhUlN7qCmC8WLrpq8jpkLVqgZ00OiA7pRyq"
      );
      let packageId;
      packages.forEach((item) => {
        if (item.name === selectedPlan) {
          packageId = item.id.toString();
        }
      });
      const lineItems = {
        package_id: packageId,
      };

      try {
        const token = "Bearer " + localStorage.getItem("token");

        const headers = {
          Authorization: token,
        };

        const response = await newRequest.post(
          "/create-checkout-session",
          lineItems,
          { headers }
        );

        const result = stripe.redirectToCheckout({
          sessionId: response.data.id,
        });

        if (result.error) {
          console.log(result.error);
        }
      } catch (error) {
        if (
          error?.response?.data?.message === "invalid token" ||
          error?.response?.data?.message === "token expired"
        ) {
          alert("session expired please login");
        } else if (error?.response?.status === 500) {
          alert("something went wrong... Try again!");
        }
      }
    };

    makePayment();
  };

  return (
    <div className="container mx-auto">
      <h1 className="font-bold text-[35px] sm:text-[40px] tracking-[1.6px] text-[#0F172A] text-center my-[30px]">
        Choose Your Plan For {priceFor}
      </h1>
      <div className="w-[200px] md:w-auto h-auto md:h-[36px] flex items-start md:items-center justify-center flex-col md:flex-row gap-[30px] mx-auto mb-[3rem]">
        {features.map((item, idx) => (
          <div
            key={idx}
            className={`md:w-auto flex items-center justify-between gap-[16px] md:pl-[16px] ${
              idx !== 0 && "md:border-l-2 border-[#D1CFCD]"
            } `}
          >
            <img src={item.icon} alt="" className="w-[24px] h-[24px]" />
            <span className="text-[16px] text-[#3C3C3C]">{item.text}</span>
          </div>
        ))}
      </div>

      <div className="lg:h-[350px] flex items-center justify-between gap-[20px] flex-col lg:flex-row my-3">
        {packages.map((plan) => (
          <div
            key={plan.id}
            className={`transition-all duration-300 pricing-card w-[270px] sm:w-[358px] h-[290px] sm:h-[315px] my-4 lg:mx-4 rounded-[15px] flex items-center justify-center pricing-shadow relative overflow-hidden bg-[#FFFFFF] pricing-cards ${
              selectedPlan === plan.name ? " scale-110" : ""
            }`}
            onClick={() => {
              setSelectedPlan(plan.name);
            }}
          >
            <div className="w-full flex item-center justify-end p-[20px] absolute top-0 right-0">
              <div className="w-[25px] h-[25px] border-[1px] border-[#FF9A06] rounded-[50%] flex items-center justify-center">
                <div
                  className={`w-[12px] h-[12px] rounded-[50%] ${
                    selectedPlan === plan.name ? "bg-[#FF9A06]" : "bg-[#FFFFFF]"
                  }`}
                ></div>
              </div>
            </div>
            {plan?.discountPercent && (
              <div className="w-[200px] bg-[#2563EB] h-[50px] rotate-[-47deg] absolute top-[30px] left-[-52px] flex items-center justify-center">
                <p className="text-[#FFFFFF] text-[24px]">35% OFF</p>
              </div>
            )}

            <div className="flex items-center justify center flex-col gap-[8px]">
              <p className="text-[28px] tracking-[1.6px] font-medium font-[#222B42] capitalize">
                {plan?.name}
              </p>
              {plan?.discountPercent ? (
                <>
                  <p className="text-[30px] tracking-[1.6px] font-medium text-[#5D6479] line-through">
                    {plan?.originalPrice}
                  </p>
                  <p className="text-[50px] tracking-[1.6px] font-medium text-[#222B42]">
                    {plan?.discountedPrice}
                  </p>
                </>
              ) : (
                <p className="text-[50px] tracking-[1.6px] font-medium font-[#222B42]">
                  {plan?.price}$
                </p>
              )}

              <p className="text-[18px] tracking-[1.6px] font-medium font-[#222B42] capitalize">
                For {plan?.type}
              </p>
            </div>
          </div>
        ))}
      </div>

      <button
        className="block w-[270px] sm:w-[350px] md:w-[429px] px-[24px] py-[14px] bg-[#2563EB] rounded-[300px] text-[24px] text-[#FFFFFF] text-center font-bold mx-auto mt-[30px]"
        onClick={handlePayment}
      >
        Get Plan
      </button>
    </div>
  );
}

export default PricingPlan;
