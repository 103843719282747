import React from "react";
import { Link, useLocation } from "react-router-dom";

function Footer() {
  const path = useLocation();
  return (
    <footer id="footer" className="bg-[#3E5FFF] w-full px-[80px] py-[20px]">
      {path.pathname === "/login" ||
      path.pathname === "/register" ||
      path.pathname === "/forget-password" ||
      path.pathname === "/reset-password" ||
      path.pathname === "/verify" ||
      path.pathname === "/payment" ? (
        <>
          <div>
            <span className="text-[#F2F2F2] block text-center">
              Copyright © 2024 9D VPN. All Rights Reserved
            </span>
          </div>
        </>
      ) : (
        <div className="container py-[24px] px-[20px] mx-auto">
          <div className="flex items-center w-full justify-between flex-col lg:flex-row gap-[34px]">
            <div className="flex items-center lg:items-start flex-col text-[#ffffff] gap-[34px] w-full lg:w-auto">
              <div>
                <span className="text-[32px] leading-[37.5px] font-bold">
                  9D VPN
                </span>
              </div>
              <ul className="flex items-center justify-between flex-col md:flex-row gap-[24px]">
                <li className="underline text-[#F2F2F2] text-[16px] tracking-[1px]">
                  <Link to="/terms-and-conditions">Terms & Conditions</Link>
                </li>
                <li className="underline text-[#F2F2F2] text-[16px] tracking-[1px]">
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li className="underline text-[#F2F2F2] text-[16px] tracking-[1px]">
                  <a href="https://www.9dtechnologies.com/contact-us/">
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>

            <div className="flex items-center lg:items-start flex-col justify-between text-[#ffffff] gap-[34px] w-full lg:w-auto">
              <ul className="flex items-center justify-between gap-[24px] h-[35px]">
                {/* <!-- <li className="underline text-[#F2F2F2] text-[16px] tracking-[1px]">
              <a href="#"><img src="images/youtube.svg" alt="" /></a>
            </li>
            <li className="underline text-[#F2F2F2] text-[16px] tracking-[1px]">
              <a href="#"><img src="images/facebook.svg" alt="" /></a>
            </li>
            <li className="underline text-[#F2F2F2] text-[16px] tracking-[1px]">
              <a href="#"><img src="images/twitter.svg" alt="" /></a>
            </li>
            <li className="underline text-[#F2F2F2] text-[16px] tracking-[1px]">
              <a href="#"><img src="images/instagram.svg" alt="" /></a>
            </li>
            <li className="underline text-[#F2F2F2] text-[16px] tracking-[1px]">
              <a href="#"><img src="images/linkedin.svg" alt="" /></a>
            </li> --> */}
              </ul>
              <div>
                <span className="text-[#F2F2F2] block text-center">
                  Copyright © 2024 9D VPN. All Rights Reserved
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </footer>
  );
}

export default Footer;
