import { createContext, useContext, useEffect, useState } from "react";
import { decodeToken } from "react-jwt";
import { useNavigate } from "react-router-dom";
import newRequest from "../utils/newRequest";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  // Synchronize state with localStorage on initial render
  useEffect(() => {
    const savePackages = async () => {
      const response = await newRequest.get("/packages");
      localStorage.setItem("packages", JSON.stringify(response.data.packages));
    };
    savePackages();
  }, []);

  const login = async (email, password) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await newRequest.post(
        `/login`,
        {
          email,
          password,
        },
        {
          headers: {
            platform: "desktop",
          },
        }
      );
      if (response.status === 200) {
        const userInfo = decodeToken(response.data.token);
        setUser(userInfo);

        // storing token to local storage
        localStorage.setItem("token", response.data.token);
        // localStorage.setItem("email", userInfo.email);
        navigate("/");
      } else {
        throw new Error(
          response.data.message || "Unknown error occurred during registration"
        );
      }

      setIsLoading(false);
    } catch (err) {
      console.log(err);
      const errMsg = err.response
        ? err.response.data.message
        : "Network error, please try again";
      setError(errMsg);
      setIsLoading(false);
      setUser(null);
    }
  };

  const signup = async (name, email, password, confirmPassword) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await newRequest.post(`/signup`, {
        name,
        email,
        password,
        confirmPassword,
      });

      if (response.status === 200 || response.status === 201) {
        // setUser(response.data);
        console.log(response.data.message);
        localStorage.setItem("email", email);
        navigate("/verify");
      } else {
        setError(response.data.message);
        throw new Error(
          response.data.message || "Unknown error occurred during registration"
        );
      }
    } catch (err) {
      const errMsg =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : err.message || "Network error, please try again";
      setError(errMsg);
    } finally {
      setIsLoading(false);
    }
  };

  const forgetPassword = async (email) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await newRequest.post(`/forget-password`, {
        email,
      });

      if (response.status === 200) {
        setError(null);
        localStorage.setItem("email", email);
        navigate("/verify?next=reset-password");

        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      const errMsg =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : err.message || "Network error, please try again";
      setError(errMsg);
      setIsLoading(false);
    }
  };

  const resetPassword = async (email, password, confirmPassword) => {
    setError(null);
    setIsLoading(true);
    try {
      const response = await newRequest.post(`/reset-password`, {
        email,
        password,
        confirmPassword,
      });

      if (response.status == 200) {
        console.log("password changed successfully");
        setError(null);
        localStorage.removeItem("email");
        navigate("/login");
      }
    } catch (err) {
      console.log(err);
      const errMsg =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : err.message || "Network error, please try again";
      setError(errMsg);
    }
    setIsLoading(false);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        isLoading,
        error,
        login,
        signup,
        setUser,
        forgetPassword,
        resetPassword,
        setError,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
