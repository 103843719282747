import Aos from "aos";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

function Profile() {
  
  const {user, setUser} = useAuth()
  useEffect(() => {
    window.scrollTo(0, 0);
    Aos.init({
      duration: 0,
      easing: "ease-in",
      once: true,
    });
  }, []);
  
  const navigate = useNavigate()
  useEffect(() => {
    const token = localStorage.getItem("token")
    if(!token){
      //No User 
      setUser(null)
      navigate("/")
    } 
  }, [user]);

  return (
    <>
      <div className="bg-gradient-to-r from-[#F1F5F9] to-[#FFFFFF] w-full py-16">
        <div className="flex flex-col items-center justify-between h-full">

          <div className=" container mx-auto rounded-[12px] border-[1px] border-[#ECECEC] p-[50px] bg-[#FFFFFF]">
            <h1 className="mb-10 text-[30px] sm:text-[40px] text-[#222B42] font-bold  ">
              My Subscriptions
            </h1>

            <div className="mb-10">
              <p className="text-[18px] text-[#5C6977] mb-3 ">Current Plan</p>
              <div className="border-[1px] border-[#DDDDDD] rounded-[20px] py-8 px-8 md:px-12 ">
                <div className="flex flex-col md:flex-row items-start mb-2 md:mb-0 md:items-center justify-between">
                  <p className="text-[20px] sm:text-[24px] text-[#222B42] font-bold uppercase">
                    9D VPN {user?.package?.name} ({user?.package?.name !== "free" && user?.package?.platform})
                  </p>
                  <Link to="/payment" className="text-[20px] sm:text-[24px] text-[#3E5FFF] underline cursor-pointer ">
                    Change or Update Plan
                  </Link>
                </div>
                {user?.package?.name !== "free" &&  (<p className="text-[#5C6977] py-[6px] ">
                  Next payment {user?.package?.price}$ on {user?.package?.end_date?.split("T")[0]}
                </p>)}
              </div>
            </div>

            <div className="mb-10">
              <p className="text-[18px] text-[#5C6977] mb-3 ">
                Billing Information
              </p>
              <div className="border-[1px] border-[#DDDDDD] rounded-[20px] py-8 px-8 md:px-12 ">
                <p className="text-[20px] sm:text-[24px] text-[#222B42] font-bold uppercase">{user?.name}</p>
                <p className="text-[#5C6977] py-[6px] ">
                 {user?.email}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;
