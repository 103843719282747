import React from "react";

function WhyUse() {
  const data = [
    {
      icon: "/images/access.svg",
      title: "Access any Website in any Country",
      desc: "Overcome geographical limitations to access any website from anywhere! Achieve entry to websites restricted or censored by governmental, educational, or workplace authorities. Navigate through firewalls to unlock Facebook, stream YouTube videos, and bypass restrictions on VOIP services. 9D VPN enables you to bypass all these barriers with its complimentary VPN application.",
    },
    {
      icon: "/images/surf.svg",
      title: "Surf the Web Anonymously",
      desc: "Shield yourself from ISP surveillance and keep websites from tracking and targeting you with ads. 9D VPN modifies your IP address to ensure your online presence remains anonymous and your internet actions are hidden from curious eyes and businesses.",
    },
    {
      icon: "/images/protect.svg",
      title: "Secure VPN Encryption",
      desc: `Protect your digital footprint with "Secure VPN encryption" from 9D VPN, ensuring your data traffic is safeguarded against interception and scrutiny. This feature encrypts your internet connection, making your online activities secure and invisible to outsiders, including ISPs, cyber threats, and advertisers. With 9D VPN, you gain not only anonymity but also a fortified barrier against digital vulnerabilities.`,
    },
  ];

  return (
    <section
      id="whyUse9dVpn"
      className="bg-gradient-to-b from-[#F1F5F9] to-[#FFFFFF00] py-[120px]"
      data-aos="fade-down"
      data-aos-duration="500"
      data-aos-once="true"
    >
      <div className="container px-[24px] mx-auto h-fit">
        <div className="flex items-center justify-center">
          <h1 className="font-bold text-[40px] lg:text-[56px] text-center text-[#3C3C3C] relative inline-block">
            Why 9D VPN?<span className="hidden sm:block heading-ring"></span>
          </h1>
        </div>

        <div className="flex justify-between mt-[72px] flex-col lg:flex-row mb-[120px]">
          {data.map((item, idx) => (
            <div key={idx} className="px-[32px] py-[40px] bg-[#ffffff] rounded-[20px] xl:h-[484px] w-full lg:w-[300px] xl:w-[360px] mb-[20px] shadow">
              <img className="mb-[20px]" src={item.icon} alt="" />
              <h4 className="text-[24px] font-medium leading-[34px] mb-[20px]">
                {item.title}
              </h4>
              <p className="text-[#3C3C3C] leading-[26px]">{item.desc}</p>
            </div>
          ))}
        </div>
        <div className="block mx-auto">
          <a
            href="https://apps.apple.com/us/app/9d-vpn-fast-vpn-proxy-master/id6455989982"
            target="_blank"
            className="block mx-auto w-[233px] px-[24px] py-[15px] bg-[#2563EB] rounded-[300px] text-[24px] text-[#FFFFFF] text-center font-bold mt-[48px]"
          >
            GET IT NOW
          </a>
        </div>
      </div>
    </section>
  );
}

export default WhyUse;
